.product-card {
  width: 250px;
  height: 460px;
  perspective: 1500px;
  -moz-perspective: 1500px;
}

.product-card__side {
  transition: all 0.8s ease;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}
.product-card:hover .product-card__side {
  transform: rotateY(180deg);
}

.product-card__side__back {
  transform: rotateY(180deg);
  backface-visibility: hidden;
  transition: all 0.8s ease;
  border-radius: 10px;
  height: 460px;
  width: inherit;
  max-height: 460px;
  max-width: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.product-card:hover .product-card__side__back {
  transform: rotateY(360deg);
}

.product-card__image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.product-card__description {
  height: 260px;
  max-height: 260px;
  max-width: inherit;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.is-loading {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.hero {
  height: 600px;
  overflow: hidden;
  display: flex;
}
.hero__bg {
  flex-grow: 1;
  object-fit: cover;
}

.hero__rectangle {
  width: 300px;
  height: 300px;
  object-fit: contain;
}
